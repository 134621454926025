<template>
    <div class="wrapper">
        <vue-code-highlight class="prism" :language="language">
            {{ code }}
        </vue-code-highlight>
        <div class="copy">
            <copy-to-clipboard :textToCopy="code" buttonClass="caption font-weight-bold" buttonName="copy to clipboard" :iconButton="true"></copy-to-clipboard>
        </div>
    </div>
</template>

<script>
import { component as VueCodeHighlight } from 'vue-code-highlight'
import 'vue-code-highlight/themes/prism.css'
import 'prism-es6/components/prism-markup-templating'
// Available languages here https://prismjs.com/index.html#supported-languages
import 'prism-es6/components/prism-python'
import 'prism-es6/components/prism-r'
import 'prism-es6/components/prism-matlab'
import 'prism-es6/components/prism-sql'

const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    name: 'CodeHighlight',
    props: {
        language: { type: String, default: 'textile' },
        code: { type: String, default: '' }
    },
    components: {
        VueCodeHighlight,
        CopyToClipboard
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    position: relative;
}
.copy {
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>

<style lang="scss">
.prism {
    pre {
        padding-top: 2rem;
        code {
            background-color: transparent !important;
        }
    }
}
</style>
